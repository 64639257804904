export enum TileSize {
  small = 19.5,
  normal = 40,
  large = 50
}

export enum TileType {
  headline = 0,
  text = 1,
  map = 2,
  weather = 3
}

export enum ModalType {
  feWo = 0,
  book = 1,
  info = 2,
  cookie = 3,
  none = 4
}

export enum FeWoDescription {
  person = 'Personen',
  maxPerson = 'Max. Personen',
  size = 'Größe in m\u00B2',
  room = 'Zimmer',
  sleepRoom = 'Schlafzimmer',
  livingRoom = 'Wohnzimmer',
  diningRoom = 'Küche/Esszimmer',
  balcony = 'Balkon in m\u00B2'
}

export interface BookingRequestPayload {
  apartment: string;
  staying: {
    start: string;
    end: string;
  };
  additional?: {
    childbed?: boolean;
    highchair?: boolean;
    bicycle?: boolean;
    pet?: boolean;
  };
  person: {
    name: string;
    surname: string;
    address: string;
    location: string;
    zipcode: string;
    mail: string;
    phone: string;
  };
  people: {
    adult: number;
    child?: number;
  };
  message?: string;
}
