<div class="container mx-auto py-0 py-xs-1 py-md-0 px-1">
  <div class="content my-md-3 my-1">
    <p>
      <strong>Stellen Sie ihre Cookie Einstellungen ein.</strong>
    </p>
    <table class="table-text" mdbTable>
      <thead>
        <tr>
          <th *ngFor="let head of headElements" scope="col">{{head}}</th>
        </tr>
      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let element of infoText; index as i">
          <th >{{element.headerText}}</th>
          <td >{{element.contentText}}</td>
          <td>
            <div class="custom-control custom-switch" >
              <input type="checkbox" class="custom-control-input" id="{{'customSwitch' + i}}" checked />
              <label class="custom-control-label" for="{{'customSwitch' + i}}" (click)="triggerSwitch(i)"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button  mdbBtn type="button" size="md" block="true" (click)="accept()">
      <a (click)="accept()">Speichern</a>
    </button>
  </div>
</div>
