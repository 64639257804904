import { ModalTemplateComponent } from './../modal-template/modal-template.component';
import { environment } from './../../environments/environment';
import { TileModels } from '../../model/FeWoTile/tile-models';
import { Component, OnInit, ViewChild, AfterViewInit, DoCheck, Input } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { LocalStorageService } from 'src/app/service/localStorage/local-storage.service';
import { BackendRequestService } from 'src/app/service/backend_request/backend-request.service';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements AfterViewInit, DoCheck {
  @ViewChild(ModalDirective, { static: false })
  frame!: ModalDirective;

  @ViewChild(ModalTemplateComponent, { static: false })
  modalTemplate!: ModalTemplateComponent;

  @Input()
  contentModel!: TileModels;

  constructor(
    private localStorage: LocalStorageService,
    private backendRequest: BackendRequestService
  ) { }


  ngAfterViewInit() {
    if (!this.localStorage.hasAccepted() && this.contentModel) {
      this.open();
    }

  }
  ngDoCheck() {
    if (this.localStorage.hasAccepted() && this.frame) {
      this.close();
    }
  }
  // Parent function
  open() {
    this.frame.show();
  }
  //
  close() {
    this.localStorage.changeAccepted(true);
    this.frame.hide();
  }

  openModal() {
    this.modalTemplate.open();
  }

}
