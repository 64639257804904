import { environment } from '../../environments/environment';
import { TileModels } from '../../model/FeWoTile/tile-models';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy
} from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { ModalType } from '../../model/tile-size.enum';
import { ScrollControlService } from 'src/app/service/scrollControl/scroll-control.service';
import { BookingEventService } from 'src/app/service/booking-event/booking-event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('frame', { static: false })
  frame!: ModalDirective;

  @Input()
  contentModel!: TileModels;

  titleHeader!: string;
  modalType!: ModalType;
  eventSubscription!: Subscription;
  showButton = false;

  constructor(
    private scrollControl: ScrollControlService,
    private bookingEvent: BookingEventService) {
  }

  ngOnInit() {
    this.eventSubscription = this.bookingEvent.eventItem$
      .subscribe(item => {
        if (item && this.titleHeader === 'Anfrage') {
          this.open();
          this.bookingEvent.closeBookingModal();
        }
      });
    if (this.contentModel) {
      this.titleHeader = this.contentModel.titleName;
      this.modalType = this.contentModel.modalType;
    }
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  // Parent function
  open() {
    this.frame.show();
    this.scrollControl.blockScroll();
    if (this.scrollControl.isSmartPhone()) {
      this.showButton = true;
    }
  }
  close() {
    this.frame.hide();
    this.scrollControl.unBlockScroll();
    // this.showButton = false;
  }
  // frame close event
  onClose(_event: any) {
    this.scrollControl.unBlockScroll();
    this.showButton = false;
  }
  // child event listener
  onChildClose(_event: any) {
    this.close();
  }
}
