import { environment } from '../../environments/environment';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'src/app/service/localStorage/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private localStorageService: LocalStorageService,//TODO remove
    private cdr: ChangeDetectorRef) { }

}
