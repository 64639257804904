import { TileModels, InfoText } from '../../../model/FeWoTile/tile-models';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BackendRequestService } from 'src/app/service/backend_request/backend-request.service';

@Component({
  selector: 'app-info-modal-template',
  templateUrl: './info-modal-template.component.html',
  styleUrls: ['./info-modal-template.component.scss']
})
export class InfoModalTemplateComponent implements OnInit {

  @Input()
  contentModel!: TileModels;

  titleHeader!: string;
  // imagePath: Map<string, string> = new Map();
  infoText!: Array<InfoText>;

  constructor(private backendRequest: BackendRequestService) { }

  ngOnInit() {
    if (this.contentModel !== null) {
      this.titleHeader = this.contentModel.titleName;

      this.infoText = this.contentModel.infoText;
      // this.infoText.forEach(element => {
      //   if (element.images) {
      //     element.images.forEach(image => {
      //       this.getImage(image);
      //     });
      //   }
      // });
    }
  }
  isLinkSet(link: string) {
    if (link == null) {
      return false;
    }
    return (link.length > 0) ? true : false;
  }
  // async getImage(id: string, counter = 6, timer: number = 300) {
  //   if (counter === 0) {
  //     console.log('could not load image ' + id);
  //     return;
  //   }
  //   this.backendRequest.fetchImageFromCache(id).then(
  //     (val: string) => {
  //       if (!val) {
  //         setTimeout(() => {
  //           this.getImage(id, counter - 1, timer * 2);
  //         }, timer);
  //         console.log('repeate image fetch ' + id);
  //       } else {
  //         this.imagePath.set(id, val);
  //       }
  //     },
  //     (err) => {
  //       console.log('get image from cache error: ' + JSON.stringify(err));
  //     }
  //   );

  // }
  // hasImage(desc: string) {
  //   return this.imagePath.has(desc);
  // }
  // fetchImage(desc: string): string {
  //   if (this.imagePath.has(desc)) {
  //     return this.imagePath.get(desc) ?? '';
  //   }
  //   return '';
  // }

}
