import { Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/app/environments/environment';
import { BookingRequestService } from 'src/app/service/booking-request/booking-request.service';
import { Subscription, } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { BookingEventService } from 'src/app/service/booking-event/booking-event.service';
import { BookingRequestPayload } from 'src/app/model/tile-size.enum';
import * as moment from 'moment';
@Component({
  selector: 'app-reservation-template',
  templateUrl: './reservation-template.component.html',
  styleUrls: ['./reservation-template.component.scss']
})
export class ReservationTemplateComponent implements OnInit {

  @Output() closeEvent = new EventEmitter<boolean>();

  selectedStartDate!: string;
  selectedEndDate!: string;
  optionRange = Array.from(Array(8).keys());
  selectedSwitch: Set<string> = new Set();
  validationForm: UntypedFormGroup;
  senderSubscript!: Subscription;

  isSendig = false;

  constructor(
    private bookingRequest: BookingRequestService,
    private bookingEvent: BookingEventService) {
    this.validationForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z]*|\s')]),
      surname: new UntypedFormControl(null, [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z]*|\s')]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      phone: new UntypedFormControl(null, [Validators.minLength(5), Validators.pattern(/^(\+[0-9]{2}[\s-/]*)?([0-9]{2,}[\s-/]*)+$/)]),
      location: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      zipcode: new UntypedFormControl(null, [
        Validators.required, Validators.pattern(/^([a-zA-Z]{2})?[\s-]*[0-9]{4,5}[\s-]*([a-zA-Z]{2})?$/)
      ]),
      address: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
      apartmentCheck: new UntypedFormControl(null, [Validators.required]),
      adultSelected: new UntypedFormControl(1, [Validators.required]),
      childSelected: new UntypedFormControl(0, [Validators.required]),
      message: new UntypedFormControl(null),
    });
    bookingEvent.eventItem$.subscribe(item => {
      if (item) {
        if (item.includes('Patrick')) { this.validationForm.controls.apartmentCheck.setValue('Patrick'); }
        if (item.includes('Lukas')) { this.validationForm.controls.apartmentCheck.setValue('Lukas'); }
        if (item.includes('Annika')) { this.validationForm.controls.apartmentCheck.setValue('Annika'); }
      }
    });
  }

  get name() { return this.validationForm.get('name'); }
  get surname() { return this.validationForm.get('surname'); }
  get phone() { return this.validationForm.get('phone'); }
  get email() { return this.validationForm.get('email'); }
  get location() { return this.validationForm.get('location'); }
  get zipcode() { return this.validationForm.get('zipcode'); }
  get address() { return this.validationForm.get('address'); }
  get apartmentCheck() { return this.validationForm.get('apartmentCheck'); }
  get adultSelected() { return this.validationForm.get('adultSelected'); }
  get childSelected() { return this.validationForm.get('childSelected'); }
  get message() { return this.validationForm.get('message'); }

  ngOnInit() {
    moment.locale('de');
  }

  parseDate(selecteddate: string) {
    return moment(selecteddate).format('DD MMMM YYYY');
  }

  async onSubmit() {
    this.validationForm.markAllAsTouched();
    if (this.validationForm.valid && this.selectedStartDate && this.selectedEndDate) {
      this.isSendig = true;
      const senderPayload = this.createSendText();
      await this.sendMail(senderPayload);
    } else {
      alert('Bitte alle fehlenden Felder ausfüllen!');
    }
  }
  triggerSwitch(nameOfSelected: string) {
    if (this.selectedSwitch.has(nameOfSelected)) {
      this.selectedSwitch.delete(nameOfSelected);
    } else {
      this.selectedSwitch.add(nameOfSelected);
    }
  }

  createAdditionals(element: Array<string>) {
    const additional = {
      childbed: false,
      highchair: false,
      bicycle: false,
      pet: false
    };
    if (element.includes('Kinderbett')) {
      additional.childbed = true;
    }
    if (element.includes('Hochstuhl')) {
      additional.highchair = true;
    }
    if (element.includes('Fahrradmitnahme')) {
      additional.bicycle = true;
    }
    if (element.includes('Haustiere')) {
      additional.pet = true;
    }
    return additional;
  }

  createSendText(): BookingRequestPayload {
    const personName = this.validationForm.get('name')?.value;
    const personSurName = this.validationForm.get('surname')?.value;
    const personEmail = this.validationForm.get('email')?.value;
    const personPhone = this.validationForm.get('phone')?.value + '';
    const personLocation: string = this.validationForm.get('location')?.value;
    const personZipCode = this.validationForm.get('zipcode')?.value;
    const personAddress = this.validationForm.get('address')?.value;
    const apartment = this.validationForm.get('apartmentCheck')?.value;
    const adults = this.validationForm.get('adultSelected')?.value;
    const child = this.validationForm.get('childSelected')?.value;
    const addMessage: string | undefined = this.validationForm.get('message')?.value;
    const start = this.parseDate(this.selectedStartDate);
    const end = this.parseDate(this.selectedEndDate);

    const generatePayload: BookingRequestPayload = {
      apartment,
      staying: {
        start,
        end
      },
      additional: this.createAdditionals(Array.from(this.selectedSwitch)),
      person: {
        name: personName,
        surname: personSurName,
        address: personAddress,
        location: personLocation,
        zipcode: personZipCode,
        mail: personEmail,
        phone: personPhone
      },
      people: {
        adult: adults,
        child
      },
      message: addMessage
    };
    console.log(generatePayload);
    return generatePayload;
  }
  async sendMail(sendText: BookingRequestPayload) {
    // console.log(JSON.stringify(sendText, null, 2));
    // setTimeout(() => { this.closeEvent.emit(true); }, 1000);
    // return Promise.resolve(true);
    const request = this.bookingRequest.sendPayload(sendText as any).subscribe(
      (response: HttpResponse<any>) => {
        if (response.status === 200) {
          alert('Nachricht wurde erfolgreich verschickt.');
          this.validationForm.reset();
          this.closeEvent.emit(true);
        } else {
          alert('Nachricht konnte nicht verschickt werden. Versuchen Sie es später nochmal.');
        }
      },
      (error: any) => {
        alert('Fehler beim Verschicken der Nachricht: ' + JSON.stringify(error));
      },
      () => {
        this.isSendig = false;
        request.unsubscribe();
      }
    );
  }

}
