import { environment } from './environments/environment';
// import { PrefetchService } from './preload/prefetch.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MainPageComponent } from './main-page/main-page.component';
import { TileGridComponent } from './main-page/tile-grid/tile-grid.component';
import { TileTemplateComponent } from './main-page/tile-template/tile-template.component';
import { ReservationTemplateComponent } from './main-page/modal-template/reservation-template/reservation-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoModalTemplateComponent } from './main-page/modal-template/info-modal-template/info-modal-template.component';
import { HeaderComponent } from './main-page/header/header.component';
import { ModalTemplateComponent } from './main-page/modal-template/modal-template.component';
import { ApartmentsModalContentComponent } from './main-page/modal-template/apartments-modal-content/apartments-modal-content.component';
import { MapsModalContentComponent } from './main-page/modal-template/maps-modal-content/maps-modal-content.component';
import { CookieModalComponent } from './main-page/cookie-modal/cookie-modal.component';
import { CookieControlComponent } from './main-page/modal-template/cookie-control/cookie-control.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { ScrollControlService } from './service/scrollControl/scroll-control.service';
import { LocalStorageService } from './service/localStorage/local-storage.service';
import { BookingRequestService } from './service/booking-request/booking-request.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { BookingEventService } from './service/booking-event/booking-event.service';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ImageLoaderConfig, IMAGE_LOADER, NgOptimizedImage } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    TileGridComponent,
    TileTemplateComponent,
    ReservationTemplateComponent,
    InfoModalTemplateComponent,
    HeaderComponent,
    ModalTemplateComponent,
    ApartmentsModalContentComponent,
    MapsModalContentComponent,
    CookieModalComponent,
    CookieControlComponent,
  ],
  imports: [
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NgOptimizedImage
  ],
  providers: [
    ScrollControlService,
    LocalStorageService,
    BookingRequestService,
    BookingEventService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => `${environment.imageURL}${config.src}/`,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
