import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingEventService {
  private item = new BehaviorSubject<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  eventItem$ = this.item.asObservable();
  constructor() { }

  // i is name of apartment, from tile title
  // 1:patrick, 2:Lukas, 3:Annika
  openBookingModal(i: string) {
    this.item.next(i);
  }
  closeBookingModal() {
    this.item.next(null);
  }
}
