import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/app/environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingRequestService {

  constructor(private httpClient: HttpClient) { }

  public sendPayload(payload: Record<string, unknown>): Observable<HttpResponse<any>> {
    const header: HttpHeaders = new HttpHeaders().set('Accept', 'application/json');
    return this.httpClient.post(environment.backendURL, payload, { headers: header }) as Observable<HttpResponse<any>>;
  }
}
