import { ModalTemplateComponent } from '../modal-template/modal-template.component';
import { TileModels } from '../../model/FeWoTile/tile-models';
import { ModalType, TileType, TileSize } from './../../model/tile-size.enum';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
} from '@angular/core';
import { BackendRequestService } from 'src/app/service/backend_request/backend-request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from '../../service/localStorage/local-storage.service';
import { environment } from 'src/app/environments/environment';
@Component({
  selector: 'app-tile-template',
  templateUrl: './tile-template.component.html',
  styleUrls: ['./tile-template.component.scss']
})
export class TileTemplateComponent implements OnInit {
  @ViewChild(ModalTemplateComponent, { static: false })
  modalTemplate!: ModalTemplateComponent;

  @Input()
  contentModel!: TileModels;

  // tile display content
  titleHeader: string | undefined;
  description: string[] = new Array<string>();
  imagePath: string | null = null;
  modalType!: ModalType;
  tileType!: TileType;

  // tile size config
  tileWidth!: string;
  displayImg = '';
  fontSize = '3';

  constructor(
    private backendRequest: BackendRequestService,
    private localStorage: LocalStorageService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.insertContent();
    // this.checkImgFound();
  }

  openModal() {
    if (this.modalType <= 2) {
      // (window as any).ga('send', 'event', {
      //   eventCategory: 'ModalOpen',
      //   eventLabel: 'ShowModal',
      //   eventAction: 'OnClick',
      //   eventValue: 10
      // });
      this.modalTemplate.open();
    }
  }

  // checkImgFound() {
  //   if (!this.imagePath || this.imagePath.length === 0) {
  //     this.displayImg = 'd-none';
  //     this.backgroundColor = '#ff3300';
  //   }
  // }

  wichTileType() {
    return this.tileType ? this.tileType : 0;
  }

  acceptedCookie() {
    return this.localStorage.hasAccepted();
  }

  private insertContent() {
    if (this.contentModel !== null) {
      this.titleHeader = this.contentModel?.titleName;
      this.tileWidth = this.getTileSize(this.contentModel?.tileSizeType ?? NaN) + 'rem';
      this.modalType = this.contentModel?.modalType;
      this.tileType = this.contentModel?.tileType;
      if (this.contentModel?.images !== null && this.contentModel.images.length > 0) {
        this.imagePath = this.contentModel.images[0];
      } else {
        this.displayImg = 'd-none';
        // this.backgroundColor = '#ff3300';
      }
    }
  }

  private getTileSize(tileSizeType: number): number {
    if (tileSizeType === 0) { return TileSize.small; }
    if (tileSizeType === 1) { return TileSize.normal; }
    if (tileSizeType === 2) { return TileSize.large; }
    return TileSize.small;
  }

}
