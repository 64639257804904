<div class="card flip-card" (click)="openModal()" [style.width]="tileWidth">
  <div class="tile-frame flip-card-front text-center">
    <div *ngIf="tileType === 0">
      <img *ngIf="imagePath !== null" [ngSrc]="imagePath" [alt]="imagePath" class="card-filter" width="1280" height="1100" priority>
      <div class="text-over-img">
        <h3 class="cyan-lighter-hover" [style.font-size]="fontSize + 'em'">{{titleHeader}}<span></span></h3>
      </div>
      <div class="front-text">
        <p class="cyan-lighter-hover" *ngFor="let element of description; let i = index"
          [style.top]="(1 + (25 * i)) + 'px'">{{element}}</p>
      </div>
    </div>
    <div *ngIf="tileType === 2 && acceptedCookie()">
      <iframe title="openstreetmap-tile" width="312" height="312" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
        src="https://www.openstreetmap.org/export/embed.html?bbox=9.321601688861849%2C47.67515342218065%2C9.325142204761507%2C47.67676436747825&amp;layer=hot&amp;marker=47.675958901046364%2C9.323371946811676"
        ></iframe>
      <div class="text-over-img">
        <p class="h5 cyan-hover" [style.color]="'black'" [style.bottom]="'50px'" [style.text-align]="'center'" [style.font-size]="'1em'"><a
            href="https://www.openstreetmap.org/?mlat=47.67596&amp;mlon=9.32337#map=19/47.67596/9.32337&amp;layers=H">Größere
            Karte anzeigen</a></p>
      </div>
    </div>
    <div *ngIf="tileType === 3">
      <iframe marginheight='0' marginwidth='0' scrolling='no' width='480' height='240' name='FC1'
        style='border:1px solid;border-color:transparent;'
        src='https://api.wetteronline.de/wetterwidget?gid=10929&modeid=FC1&seourl=hagnau-am-bodensee&locationname=Hagnau am Bodensee'></iframe>
    </div>

  </div>
</div>
<app-modal-template [contentModel]="contentModel"></app-modal-template>
