<div class="container mx-auto py-0 py-xs-1 py-md-0 px-1">
  <div class="content my-md-3 my-1">
    <div class="description py-1">
      <section>
        <div class="m-1" *ngFor="let item of infoText">
          <div *ngIf="item.headerText !== null">
            <h1>{{item.headerText}}</h1>
          </div>
          <p>{{item.contentText}}
            <span *ngIf="item.link && item.link.length > 0">
              <a [href]="item.link">Hier mehr</a>
            </span>
          </p>
          <div *ngIf="item && item.images.length > 0" class="info-carousel">
            <mdb-carousel class="carousel" [animation]="'slide'">
              <mdb-carousel-item class="carousel-picture" *ngFor="let image of item.images">
                <img class="d-block current-img" [ngSrc]="image" [alt]="image" width="700" height="700" loading="lazy" [priority]="false">
              </mdb-carousel-item>
            </mdb-carousel>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
