<div mdbModal #frame="mdbModal" style="overflow-y: auto" class="modal" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="modalTemplateLabel" aria-hidden="true" (close)="onClose($event)">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100" [id]="titleHeader">{{titleHeader}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span aria-hidden="true" >X</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div *ngIf="modalType === 0">
          <app-apartments-modal-content [contentModel]="contentModel" (closeEvent)="onChildClose($event)">
          </app-apartments-modal-content>
        </div>
        <div *ngIf="modalType === 1">
          <app-reservation-template (closeEvent)="onChildClose($event)"></app-reservation-template>
        </div>
        <div *ngIf="modalType === 2">
          <app-info-modal-template [contentModel]="contentModel"></app-info-modal-template>
        </div>
        <div *ngIf="modalType === 3">
          <app-cookie-control [contentModel]="contentModel" (closeEvent)="onChildClose($event)"></app-cookie-control>
        </div>
        <!-- <div *ngIf="modalType == 3">
          <app-maps-modal-content></app-maps-modal-content>
        </div> -->
      </div>
    </div>
  </div>
</div>
<button *ngIf="showButton" class="phoneButton" mdbBtn (click)="close()"
  mdbWavesEffect>
  <p>X</p>
</button>
