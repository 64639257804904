<div class="mx-1 px-md-3 px-1">
  <div class="container pt-2" [formGroup]="validationForm">
    <div class="row">
      <div class="col-12">
        <h5 class="text-center">Anschrift</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="name" class="form-control" formControlName="name" minLength="8">
          <label for="name">Vorname</label>
          <mdb-error *ngIf="name?.invalid && (name?.dirty || name?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="surname" class="form-control" formControlName="surname"
            minLength="8">
          <label for="surname">Name</label>
          <mdb-error *ngIf="surname?.invalid && (surname?.dirty || surname?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="email" id="email" class="form-control" formControlName="email">
          <label for="email">E-Mail Adresse</label>
          <mdb-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            Falsche Eingabe</mdb-error>
        </div>

      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="phone" class="form-control" formControlName="phone">
          <label for="phone">Telefonnummer (Optional)</label>
          <mdb-error *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="location" class="form-control" formControlName="location">
          <label for="location">Ortsname</label>
          <mdb-error *ngIf="location?.invalid && (location?.dirty || location?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="zipcode" class="form-control" formControlName="zipcode">
          <label for="zipcode">PLZ</label>
          <mdb-error *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="md-form">
          <input mdbInput mdbValidate type="text" id="address" class="form-control" formControlName="address">
          <label for="address">Straße und Hausnummer</label>
          <mdb-error *ngIf="address?.invalid && (address?.dirty || address?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="text-center">Ferienwohnung auswählen</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="md-form">
          <select formControlName="apartmentCheck" class="browser-default custom-select mb-4"
            [style.background-color]="'transparent'" >
            <option [ngValue]="null" disabled>Ferienwohnung auswählen</option>
            <option [ngValue]="'Patrick'" selected>Ferienwohnung Patrick</option>
            <option [ngValue]="'Lukas'">Ferienwohnung Lukas</option>
            <option [ngValue]="'Annika'">Ferienwohnung Annika</option>
          </select>
          <mdb-error *ngIf="apartmentCheck?.invalid && (apartmentCheck?.dirty || apartmentCheck?.touched)">
            Bitte auswählen</mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="text-center">Zeitraum auswählen</h5>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-5 mb-2">
        <h5 class="text-center">Anreise Tag</h5>
        <h6 *ngIf="selectedStartDate" class="text-center text-success">
          {{parseDate(selectedStartDate)}}
        </h6>
        <div class="date-picker-modal">
          <dl-date-time-picker startView="month" maxView="year" minView="day" [(ngModel)]="selectedStartDate"
            [ngModelOptions]="{standalone: true}" >
          </dl-date-time-picker>
        </div>
        <div class="pt-2">
          <mdb-error *ngIf="!selectedStartDate" [style.position]="'static'">
            Bitte wählen</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-5 mb-2">
        <h5 class="text-center">Abreise Tag</h5>
        <h6 *ngIf="selectedEndDate" class="text-center text-success">
          {{parseDate(selectedEndDate)}}</h6>
        <div class="date-picker-modal">
          <dl-date-time-picker startView="month" maxView="year" minView="day" [(ngModel)]="selectedEndDate"
            [ngModelOptions]="{standalone: true}" >
          </dl-date-time-picker>
        </div>
        <div class="pt-2">
          <mdb-error *ngIf="!selectedEndDate" [style.position]="'static'">
            Bitte wählen</mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="text-center">Personenzahl auswählen</h5>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-5 ">
        <h5 class="text-center">Anzahl erwachsene Personen (ab 15)</h5>
        <div class="md-form mb-4">
          <select formControlName="adultSelected" class="browser-default custom-select "
            [style.background-color]="'transparent'" >
            <option [ngValue]="1">1</option>
            <option *ngFor="let i of optionRange" [ngValue]="i + 2">{{i + 2}}</option>
          </select>
          <mdb-error *ngIf="adultSelected?.invalid && (adultSelected?.dirty || adultSelected?.touched)">
            Bitte wählen</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-5 ">
        <h5 class="text-center">Anzahl Kinder (unter 15)</h5>
        <div class="md-form mb-4">
          <select class="browser-default custom-select mb-4" [style.background-color]="'transparent'"
             formControlName="childSelected">
            <option [ngValue]="0">0</option>
            <option *ngFor="let i of optionRange" [ngValue]="i+1">{{i+1}}</option>
          </select>
          <mdb-error *ngIf="childSelected?.invalid && (childSelected?.dirty || childSelected?.touched)">
            Bitte wählen</mdb-error>
        </div>
      </div>
      <div class="col-12 col-md-5 ">
        <h5 class="text-center">Weitere Konfiguration</h5>
        <div class="row justify-content-md-center">
          <div class="col-12 p-1 pl-5">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch1">
              <label class="custom-control-label" for="customSwitch1"
                (click)="triggerSwitch('Kinderbett')">Kinderbett</label>
            </div>
          </div>
          <div class="col-12 p-1 pl-5">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch4">
              <label class="custom-control-label" for="customSwitch4"
                (click)="triggerSwitch('Hochstuhl')">Hochstuhl</label>
            </div>
          </div>
          <div class="col-12 p-1 pl-5">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch2">
              <label class="custom-control-label" for="customSwitch2"
                (click)="triggerSwitch('Fahrradmitnahme')">Fahrradmitnahme</label>
            </div>
          </div>
          <div class="col-12 p-1 pl-5">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitch3">
              <label class="custom-control-label" for="customSwitch3"
                (click)="triggerSwitch('Haustiere')">Haustiere</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="text-center">Zusätzliche Anmerkungen</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="md-form">
          <textarea type="text" class="form-control md-textarea" id="message" rows="2" mdbInput
            formControlName="message"></textarea>
          <label for="message">Nachricht</label>
          <mdb-error *ngIf="message?.invalid && (message?.dirty || message?.touched)">
            Falsche Eingabe</mdb-error>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button [disabled]="validationForm?.invalid || !validationForm?.dirty || !validationForm?.touched || isSendig"
      type="submit" mdbBtn  (click)="onSubmit()"
      mdbWavesEffect><a>Senden</a></button>
  </div>
</div>
