<div *ngIf="imagePath && imagePath.length > 0 && expStyle" class="container-fluid fullImg">
  <div class="container-fluid-container">
  </div>
  <mdb-carousel [animation]="'slide'">
    <mdb-carousel-item class="carousel-picture" *ngFor="let image of imagePath">
      <img class="d-block fullImg-picture" [ngSrc]="image" [alt]="image" width="500" height="500" size="80vh" loading="lazy">
    </mdb-carousel-item>
    <button class="zoomButton" mdbBtn type="button" (click)="changeZoomIcon()">
      <i class="fas fa-down-left-and-up-right-to-center"></i>
    </button>
  </mdb-carousel>
</div>
<div class="container mx-auto p-1">
  <div class="row">
    <div *ngIf="imagePath && imagePath.length > 0" class="col-12 col-md-6 apartment-carousel">
      <mdb-carousel [animation]="'slide'">
        <mdb-carousel-item class="carousel-picture" *ngFor="let image of imagePath">
          <img class="d-block apartment-picture" [ngSrc]="image" [alt]="image" [width]="100" [height]="100" loading="lazy" [priority]="false">
        </mdb-carousel-item>
        <button class="zoomButton" mdbBtn type="button" (click)="changeZoomIcon()">
          <i class="fas fa-up-right-and-down-left-from-center"></i>
        </button>
      </mdb-carousel>
    </div>
    <div class="col-12 col-md-6">
      <div class="py-1">
        <button class="booking-button" mdbBtn type="button" block="true" (click)="openBooking()"
          mdbWavesEffect>Buchungsanfrage stellen!</button>
      </div>
      <div class="content my-md-3 my-1">
        <h2>Beschreibung</h2>
        <div class="description py-1">
          <dl class="row mb-1" *ngFor="let element of apartmenDesc">
            <div class="col-12 m-0 p-0 my-2">
              <hr class="my-0 mx-xs-0 mx-3 py-0 px-xs-0 px-3 horizontal-line">
            </div>
            <dt class="col-6 pr-0">{{element[0]}}</dt>
            <dd class="col-6 my-0 pr-0">{{element[1]}}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="icons my-md-3 my-1" [style.background-color]="'transparent'">
        <h2>Ausstattung</h2>
        <div class="pb-2 pb-sm-3"></div>
        <table mdbTable small="true">
          <thead>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let el of equipment; let i = index">
              <td class="pl-0 w-100">{{el[0]}}</td>
              <td class="pl-0">
                <div *ngIf="isValueTrue(el[1]); else elseBlock">
                  <i class="fas fa-check"></i>
                </div>
                <ng-template #elseBlock>
                  <i class="fas fa-times"></i>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="equipment-button" mdbBtn type="button" size="sm" block="true"
        (click)="changeEquipIcon()">
        <i class="fas {{equipmentIcon}}"></i>
      </button>
    </div>
    <div class="col-12 col-md-6">
      <div class="price my-md-3 my-1">
        <h2>Preis pro Nacht</h2>
        <table mdbTable mdbTableScroll small="true" scrollX="true" maxWidth="550">
          <thead>
            <tr>
              <th *ngFor="let head of priceHeader" scope="col">{{head}}</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol *ngFor="let element of preisListe">
              <td *ngFor="let el of element ;let i = index">
                <span *ngIf="isNotZero(i)">*</span>{{postCurrency(el)}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="">
          <p><b>{{saisonText1}}</b></p>
          <p><b>{{saisonText2}}</b></p>
          <br>
          <p><b>*Im Preis enthalten sind:</b></p>
          <p>aktuelle Mehrwertsteuer</p>
          <p>Endreinigung</p>
          <p>Strom, Wasser, Heizung</p>
          <p>Hand- und Duschtücher, Geschirrtücher und Bettwäsche</p>
          <br>
          <p><b>Kurtaxe:</b></p>
          <p>In der Nebensaison: Kurtaxe pro Tag: 1,50 EUR.</p>
          <p>In der Hauptsaison: Kurtaxe pro Tag: 3,00 Euro.</p>
          <br>
          <p><b>Von der Kurtaxe befreit sind:</b></p>
          <p>Kinder bis 16 Jahre</p>
          <p>schwerbehinderte Personen ab 80% Behinderung</p>
          <p>Begleitpersonen von Schwerbehinderten, wenn die Notwendigkeit einer Begleitperson mit dem Merkzeichen „B“
            im Ausweis nachgewiesen wird</p>
          <p>Gäste, die sich aus beruflichen Gründen in der Gemeinde aufhalten (Geschäftsreisende)</p>
          <p>Weiterführende Informationen finden Sie <a
              href='https://www.gemeinde-hagnau.de/datenverarbeitung'>hier</a>.</p>
          <br>
          <p>Alle Angaben sind ohne Gewähr von Richtigkeit und Vollständigkeit</p>
        </div>
      </div>
    </div>
  </div>
</div>
