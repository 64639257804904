import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class ScrollControlService {
  device: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.device = deviceService.isMobile();
  }

  blockScroll() {
    if (this.device) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  unBlockScroll() {
    if (this.device) {
      document.body.style.position = 'static';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  isSmartPhone() {
    return this.device;
  }
}
