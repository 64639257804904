import { TileModels } from '../../../model/FeWoTile/tile-models';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BackendRequestService } from 'src/app/service/backend_request/backend-request.service';
import { BookingEventService } from 'src/app/service/booking-event/booking-event.service';

@Component({
  selector: 'app-apartments-modal-content',
  templateUrl: './apartments-modal-content.component.html',
  styleUrls: ['./apartments-modal-content.component.scss']
})
export class ApartmentsModalContentComponent implements OnInit {

  @Input()
  contentModel!: TileModels;

  @Output() closeEvent = new EventEmitter<boolean>();

  // modal content
  // titleHeader: string;
  imagePath!: string[];
  apartmenDesc!: Array<[string, string]>;
  equipment!: Array<[string, boolean]>;
  // apartment equipments header
  // headElements = [ 'Beschreibung', 'Vorhanden'];
  equipmentIcon = 'fa-angle-double-down';
  displayMode = 'none';
  expStyle = false;
  // apartment price header
  priceHeader = ['Anzahl Personen', 'Nebensaison', 'Hauptsaison', 'Anzahl Nächte'];
  preisListe!: Array<Array<string>>;

  saisonText1 = 'Nebensaison: ab 01.01. - 30.04. und 01.11. - 31.12.';
  saisonText2 = 'Hauptsaison: ab 01.05. - 31.10.';

  constructor(
    private backendRequest: BackendRequestService,
    private bookingEvent: BookingEventService
  ) { }

  ngOnInit() {
    if (this.contentModel !== null) {
      // this.titleHeader = this.contentModel.titleName;
      // this.imagePath = this.contentModel.feWoContent.images;
      // this.contentModel.feWoContent.images.map(element => {
      //   this.getImage(element);
      // });
      this.apartmenDesc = this.contentModel.feWoContent.description;
      this.equipment = this.contentModel.feWoContent.details.slice(0, 7); // this.contentModel.feWoContent.details;
      this.preisListe = this.contentModel.feWoContent.price;
      this.imagePath = this.contentModel.feWoContent.images;
    }
  }

  openBooking() {
    this.closeEvent.emit(true);
    this.bookingEvent.openBookingModal(this.contentModel.titleName);
  }
  // Trigger for expand accessories
  changeEquipIcon() {
    this.equipmentIcon = (this.equipmentIcon === 'fa-angle-double-down') ? 'fa-angle-double-up' : 'fa-angle-double-down';
    this.displayMode = (this.displayMode === 'none') ? 'table-row' : 'none';
    if (this.equipment.length !== this.contentModel.feWoContent.details.length) {
      this.equipment = this.contentModel.feWoContent.details;
    } else {
      this.equipment = this.contentModel.feWoContent.details.slice(0, 7);
    }
  }

  changeZoomIcon() {
    this.expStyle = !this.expStyle;
  }

  isValueTrue(value: boolean) {
    return value;
  }
  isNotZero(value: number) {
    return value > 0 && value < 3 ? true : false;
  }

  postCurrency(input: string) {
    return input.includes('€') ? input : input.split(/\//).join('€/');
  }

  // async getImage(id: string, counter = 6, timer: number = 300) {
  //   if (counter === 0) {
  //     return;
  //   }
  //   this.backendRequest.fetchImageFromCache(id).then(
  //     (val: string) => {
  //       if (!val) {
  //         setTimeout(() => {
  //           this.getImage(id, counter - 1, timer * 2);
  //         }, timer);
  //       } else {
  //         this.imagePath.push([val, id]);
  //       }
  //     },
  //     (err) => {
  //       console.log('get image from cache error: ' + JSON.stringify(err));
  //     }
  //   );
  // }
}
