import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { TileModels } from 'src/app/model/FeWoTile/tile-models';

@Injectable({
  providedIn: 'root'
})
export class BackendRequestService {
  //token: string;
  header: HttpHeaders;

  imageCache: Map<string, any> = new Map();

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
    this.header = new HttpHeaders({ ['Accept']: 'application/json' });
  }

  public getTilesId(): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.allTileIdURL, { headers: this.header });
  }

  public getTile(id: string): Observable<{ content: TileModels }> {
    return this.httpClient.get<{ content: TileModels }>(environment.tileDBURL + id + '/', { headers: this.header });
  }

  // public getImage(id: string): Observable<Blob> {
  //   return this.httpClient.get<Blob>(environment.imageURL + id + '/', { headers: this.header, responseType: 'blob' as 'json' });
  // }

  public getAllImagesDesc(): Observable<{ images: string[] }> {
    return this.httpClient.get<{ images: string[] }>(environment.imageURL, { headers: this.header });
  }

  // public fetchImageFromCache(desc: string): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (!this.imageCache.has(desc)) {
  //       setTimeout(() => {
  //         resolve(null);
  //       }, 1000);
  //     } else {
  //       resolve(this.imageCache.get(desc));
  //     }
  //   });
  // }

  public async loadImage(fileDescription: string) {
    const url = this.sanitizer.bypassSecurityTrustUrl(environment.imageURL + fileDescription + '/');
    this.imageCache.set(fileDescription, url);
  }
}
