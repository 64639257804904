import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { StorageObject } from '../../model/StorageObject/storage-object';

const STORAGE_KEY = 'Ferienhof-Sterk';

@Injectable()
export class LocalStorageService {

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
  }

  public hasAccepted(): boolean {
    return this.storage.get(STORAGE_KEY)?.hasAccepted || false;
  }

  public changeAccepted(hasAccepted: boolean) {
    this.storage.set(STORAGE_KEY, new StorageObject(hasAccepted));
  }

}
