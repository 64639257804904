<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="modalCookieLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">

          <p class="notify-text pt-3 pr-2" >Wir nutzten Cookies um unsere Webseite zu verbessern</p>

          <button mdbBtn class="waves-light" [style.background-color]="'darkgrey'" [style.color]="'white'"
            (click)="openModal()" mdbWavesEffect><a>Mehr erfahren
              <i class="fas fa-book ml-1"></i></a>
          </button>
          <button mdbBtn class="accept-button" (click)="close()"
            mdbWavesEffect><a>Einverstanden</a></button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal-template [contentModel]="contentModel"></app-modal-template>
