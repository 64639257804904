import { InfoText, TileModels } from '../../../model/FeWoTile/tile-models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'src/app/service/localStorage/local-storage.service';

@Component({
  selector: 'app-cookie-control',
  templateUrl: './cookie-control.component.html',
  styleUrls: ['./cookie-control.component.scss']
})
export class CookieControlComponent implements OnInit {

  @Input()
  contentModel!: TileModels;

  @Output() closeEvent = new EventEmitter<boolean>();
  titleHeader!: string;
  infoText!: Array<InfoText>;

  disableSwitch: Set<number> = new Set();

  headElements = ['Bezeichnung', 'Beschreibung', 'Einverständnis'];

  constructor(private localStorage: LocalStorageService) { }

  ngOnInit() {
    if (this.contentModel) {
      this.titleHeader = this.contentModel.titleName;
      this.infoText = this.contentModel.infoText;
    }
  }

  accept() {
    this.localStorage.changeAccepted(this.disableSwitch.size === 0);
    this.closeEvent.emit(true);
  }

  triggerSwitch(payload: number) {
    if (this.disableSwitch.has(payload)) {
      this.disableSwitch.delete(payload);
    } else {
      this.disableSwitch.add(payload);
    }
  }

}
