import { LocalStorageService } from './../service/localStorage/local-storage.service';
import { environment } from './../environments/environment';
import { TileModels } from '../model/FeWoTile/tile-models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TileGridComponent } from './tile-grid/tile-grid.component';
import { Router } from '@angular/router';
import { BackendRequestService } from '../service/backend_request/backend-request.service';
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
  @ViewChild(TileGridComponent, { static: false })
  tileGrid!: TileGridComponent;

  tileContentList: Array<TileModels> = [];
  cookieContent: TileModels | null = null; // Cookie.content as TileModels;

  listOfLoadTiles!: string[];

  constructor(
    private router: Router,
    private backendService: BackendRequestService
  ) {
    backendService.getAllImagesDesc().subscribe((response: { images: string[] }) => {
      response.images.forEach(i => backendService.loadImage(i));
    });
    this.fetchFromBackend();
  }

  private async fetchFromBackend() {
    const startProcess = this.startRequest()
      .then((list: boolean) => this.mapToTiles());
    await startProcess;
  }

  private async startRequest(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.backendService.getTilesId().subscribe(
        (response: string[]) => {
          const requestList: string[] = [...response];
          this.listOfLoadTiles = [...response];
          resolve(true);
        }
      );
    });
  }

  private mapToTiles(): Array<Promise<boolean>> {
    return this.listOfLoadTiles.map(el => this.getTilesFromDB(el));
  }

  private getTilesFromDB(tileId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.backendService.getTile(tileId).subscribe(
        (response: { content: TileModels }) => {
          const content = JSON.parse(JSON.stringify(response)).content as TileModels;
          this.mapToList(content);
          resolve(true);
        }
      );
    });
  }

  private mapToList(res: TileModels) {
    res.tileType = res.tileType ?? res.kachelType; //TODO: remove if backend fixed
    if (res.modalType === 3) {
      this.cookieContent = res;
    } else {
      this.tileContentList.push(res);
      this.tileContentList.sort((a, b) => Number(a.seqNum) - Number(b.seqNum));
    }
    // console.log(res);
    return res.tileType;
  }


}
